#root,
body,
html {
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}
html.dark {
  background-color: rgb(15, 23, 42);
}
